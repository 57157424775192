<template>
  <div>
    <b-card v-for="(item, i) in datas" :key="i">
      <router-link :to="`/kendaraan-barang/detail/${item.id}`">
        <!-- <b-badge class="mb-1" v-if="item.kendaraan">{{ item.kendaraan.jenis_kendaraan }}</b-badge> -->
        <b-badge class="mb-1" variant="light-primary" v-if="item.kendaraan"
          ><feather-icon icon="TruckIcon" class="mr-25" />{{
            item.driver?.nama_lengkap
          }}</b-badge
        >
        <b-badge class="ml-1 mb-1" variant="light-info" v-if="item.kendaraan"
          ><feather-icon icon="UserIcon" class="mr-25" />{{
            item.karyawan?.nama_lengkap
          }}</b-badge
        >
        <b-badge class="ml-1 mb-1" variant="light-danger" v-if="item.kendaraan"
          ><feather-icon icon="MapPinIcon" class="mr-25" />{{
            item.teritori?.nama_teritori
          }}
          - {{ item.alamat }}</b-badge
        >
        <h4 class="mb-2">{{ getKendaraanName(item) }}</h4>
        <b-button
          @click.prevent="detailMobil(item)"
          size="sm"
          variant="primary"
        >
          Lihat Stok
        </b-button>
      </router-link>
    </b-card>
  </div>
</template>
<script>
import { BCard, BButton, BBadge } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BBadge,
  },
  props: {
    datas: {
      type: Array,
      require: true,
    },
  },
  methods: {
    detailMobil({id, karyawan}) {
      if(karyawan) {
        this.$store.commit('kendaraan/SET_CURRENT_SALES_ID', karyawan.id)
      }
      this.$router.push(`/kendaraan-barang/detail/${id}`)
    },
    getKendaraanName(item) {
      return item.kendaraan
        ? `${item.kendaraan.nama_kendaraan} - ${item.kendaraan.plat_nomer}`
        : item.nama_gudang;
    },
  },
};
</script>
